<script setup lang="ts">
import VueQrcode from 'vue-qrcode'
import * as htmlToImage from 'html-to-image'
import PersonInfo from '~/net/models/person_info'

// import icon_vip from '~/assets/image/home/mine/icon_vip.png'
import icon_uers_vip from '~/assets/image/home/mine/icon_uers_vip@2x.png'
import icon_spread_share from '~/assets/image/home/mine/icon_spread_share@2x.png'
import icon_browsing_history from '~/assets/image/home/mine/icon_browsing_history@2x.png'
import icon_my_collected from '~/assets/image/home/mine/icon_my_collected@2x.png'
import icon_chat_room from '~/assets/image/home/mine/icon_chat_room@2x.png'

// import icon_cdk_gift from '~/assets/image/home/mine/icon_cdk_gift@2x.png'
import icon_feedback from '~/assets/image/home/mine/icon_feedback@2x.png'
import icon_seek_film from '~/assets/image/home/mine/icon_seek_film@2x.png'
import identity_title from '~/assets/image/home/identity/identity_title.png'

// import vipGoldBg from '~/assets/image/home/mine/vip_gold_bg.png'

import { personalInfo } from '~/api/mine'
import { handleCopy } from '~/utils/copy'

const isVip = ref(false)
const show = ref(false)
const showCard = ref(false)
const router = useRouter()
const loading = ref(true)
const certificate = ref('')

function goService() {
  router.push('/service')
}
const info = ref<PersonInfo>()
async function getInfo() {
  try {
    const data = await personalInfo({})
    info.value = new PersonInfo(data.data)
    isVip.value = (info.value?.vipRemainDay ?? 0) > 0
    certificate.value = data.data.certificateVO?.certificate || ''
    setTimeout(() => {
      loading.value = false
    }, 800)
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}
await getInfo()

function handleVipClick() {
  // 使用 Vue Router 跳转到 VIP 页面，假设你已经设置好了路由
  // router.push('/memberRecharge')
}

function buildSubTitle(info: PersonInfo | null): string {
  if (!info)
    return '-'
  const vipText = info.isFreeVip ? '免费' : 'VIP'
  return `${vipText}${'到期日'}: ${info.vipDueDate || '-'}`
}

async function saveCardHandle() {
  const canvas = document.getElementById('canvasDom') as HTMLCanvasElement
  try {
    const dataUrl = await htmlToImage.toPng(canvas) // 将 DOM 转换为 PNG 格式的 Data URL
    // 创建一个虚拟的下载链接，并点击下载
    const link = document.createElement('a')
    link.download = 'download.png' // 下载文件的名称
    link.href = dataUrl
    link.click()
  } catch (error) {
    console.error('保存图片出错：', error)
  }
  showCard.value = false
}
</script>

<template>
  <div h-screen px-4 ct-e8e8e8>
    <div h-10 flex flex-row-reverse pt-4>
      <van-icon size="20" color="#ffffff" mr-4 name="service-o" @click="goService" />
      <van-icon size="20" color="#ffffff" mr-4 name="contact-o" @click="showCard = true" />
    </div>
    <div mt-2 h-20 w-full flex flex-row items-center>
      <div relative mr-4 w-40>
        <ImgWrapper :src="info?.topImagesUrl" placeholder="/customer.png" />
        <img v-if="isVip" absolute bottom--3 left-2 :src="icon_uers_vip" />
      </div>
      <div w-2xl>
        <p text-xl>{{ info?.nickname || info?.userName }}</p>
        <div flex flex-row items-center text-sm>
          ID:{{ info?.userName }} &nbsp;&nbsp;
          <div w-10 border-b-bluegray rounded text-center text-sm bg-414660 ct-cfcfcf @click="handleCopy(info.id)">
            复制
          </div>
        </div>
      </div>
      <NuxtLink w-10 to="/profile">
        <van-icon size="30" mr-4 name="arrow" />
      </NuxtLink>
    </div>
    <!-- <div h-20 w-full flex flex-row items-center>
      <div w-xl text-center>
        <p text-base>{{ info?.coinBalance }}</p>
        <p text-sm ct-51535F>金币</p>
      </div>
      <div w-xl text-center>
        <p text-base>{{ info?.videoCacheNum }}/{{ info?.videoCacheLimit }}次</p>
        <p text-sm ct-51535F>缓存次数</p>
      </div>
      <div w-xl text-center>
        <p text-base>{{ info?.freeNum }}/{{ info?.freeNumLimit }}次</p>
        <p text-sm ct-51535F>免费观看</p>
      </div>
    </div> -->

    <div class="vip-info mt-8">
      <!-- <VipCard
        v-if="!info || !isVip "
        :is-local-img="true"
        :background-img="vipGoldBg"
        :prev-widget="icon_vip"
        title="开通会员"
        :title-text-style="{ color: '#563934', fontSize: '18px', fontWeight: 'bold' }"
        sub-title="尊享海量高清片库"
        :sub-title-text-style="{ color: '#563934', fontSize: '12px' }"
        @click="handleVipClick"
      >
      <template #tailWidget>
        <div class="bg3 absolute right-4 top-6 h-8 w-24 rounded-25 text-center font-sans text-base leading-8 ct-FFFFFF">
          开通会员
        </div>
      </template>
      </VipCard>
      <VipCard
        v-else
        :background-img="info.personCenterUrl"
        :prev-widget="null"
        :title="info.vipName || '-'"
        :title-text-style="{ color: info.vipColor, fontSize: '22px' }"
        :sub-title="buildSubTitle(info)"
        :sub-title-text-style="{ color: info.vipDueDateColor, fontSize: '12px', fontWeight: '500' }"
      >
      <template #tailWidget>
        <template v-if="!info" />
        <template v-else>
          <template v-if="info.isForeverVip">
            <span :style="`font-size: 16px; color: ${info.vipColor}`">${t('module_home_mine_txt5')}</span>
          </template>
          <template v-else>
            <div flex flex-col items-end justify-center :style="`font-size: 12px; color: ${info.vipColor}; font-weight: 500;`">
              <div mb-5px>倒计时</div>
              <div flex gap-3px>
                <div class="gaps text-center" :style="`color:${info.vipDueDateColor}`">
                  {{ (info.vipRemainDay || 1) - 1 }}
                </div>
                <span>天</span>
                <div class="gaps text-center" :style="`color:${info.vipDueDateColor}`">
                  {{ info.vipRemainHour || 0 }}
                </div>
                <span>时</span>
              </div>
            </div>
          </template>
        </template>
      </template>
    </VipCard> -->
    <!-- <div mt-4 h-20 w-full flex flex-row items-center>
      <NuxtLink w-xl flex flex-col items-center text-center to="/memberRecharge">
        <img w-12 :src="icon_member_recharge" />
        <p text-sm ct-E8E8E8>会员充值</p>
      </NuxtLink>
      <NuxtLink w-xl flex flex-col items-center text-center to="/promote-share">
        <img w-12 :src="icon_spread_share" />
        <p text-sm ct-E8E8E8>推广分享</p>
      </NuxtLink>
      <NuxtLink w-xl flex flex-col items-center text-center to="/purchase-history">
        <img w-12 :src="icon_purchase_history" />
        <p text-sm ct-E8E8E8>购买记录</p>
      </NuxtLink>
      <NuxtLink w-xl flex flex-col items-center text-center to="/flowRecord">
        <img w-12 :src="icon_flow_history" />
        <p text-sm ct-E8E8E8>充值记录</p>
      </NuxtLink>
    </div> -->
    <div mb-6 h-20 w-full flex flex-row items-center>
      <NuxtLink w-xl flex flex-col items-center text-center to="/browsHistory">
        <img w-12 :src="icon_browsing_history" />
        <p text-sm ct-E8E8E8>我看过的</p>
      </NuxtLink>
      <!-- <NuxtLink w-xl flex flex-col items-center text-center to="/myDownload">
        <img w-12 :src="icon_my_download" />
        <p text-sm ct-E8E8E8>我的下载</p>
      </NuxtLink> -->
      <NuxtLink w-xl flex flex-col items-center text-center to="/myCollected">
        <img w-12 :src="icon_my_collected" />
        <p text-sm ct-E8E8E8>我的收藏</p>
      </NuxtLink>
      <NuxtLink w-xl flex flex-col items-center text-center to="/group">
        <img w-12 :src="icon_chat_room" />
        <p text-sm ct-E8E8E8>交流群</p>
      </NuxtLink>
    </div>
    <div mb-12 h-20 w-full flex flex-row items-center>
      <!-- <NuxtLink w-xl flex flex-col items-center text-center to="/cdkGift">
        <img w-12 :src="icon_cdk_gift" />
        <p text-sm ct-E8E8E8>CDK兑换</p>
      </NuxtLink> -->
      <!-- <NuxtLink w-xl flex flex-col items-center text-center to="/taskCenter">
        <img w-12 :src="icon_task_center" />
        <p text-sm ct-E8E8E8>任务中心</p>
      </NuxtLink> -->
      <NuxtLink w-xl flex flex-col items-center text-center to="/promote-share">
        <img w-12 :src="icon_spread_share" />
        <p text-sm ct-E8E8E8>推广分享</p>
      </NuxtLink>
      <NuxtLink w-xl flex flex-col items-center text-center to="/feedback">
        <img w-12 :src="icon_feedback" />
        <p text-sm ct-E8E8E8>意见反馈</p>
      </NuxtLink>
      <div w-xl flex flex-col items-center text-center @click="show = true">
        <img w-12 :src="icon_seek_film" />
        <p text-sm ct-E8E8E8>求片</p>
      </div>
    </div>
    <!-- <img mt-4 w-full src="/Rectangle.png" /> -->
    <div class="banner2">
      <BannerPage :key="1" :banner-type="{ type: 1, code: 5 }" height="h-80px" />
    </div>
  </div>
  <VideoAsk v-model:show="show" @close="show = false" />
  <van-overlay :show="showCard">
    <div id="canvasDom" class="wrapper" @click.stop>
      <div class="block1 block" flex flex-col items-center p-4>
        <img mb-4 h-10.5 w-49 :src="identity_title" />
        <div text-xs ct-232323>
          <p>身份卡是除手机号/账号外，唯一可以用于恢复/切换账号的凭证，请及时保存并妥善保管，切换丢失或泄露给他人。</p>
        </div>
        <!-- <img :src="qrcode" /> -->
        <div v-if="certificate" class="card_bg">
          <p v-if="info?.nickname" class="user-name text-16px font-bold">
            {{ `微勃 ID: ${info?.nickname || info?.userName}` }}
          </p>
          <VueQrcode v-if="certificate" :value="certificate" />
          <p class="subtitle text-xl">
            请勿泄露给他人
          </p>
          <p class="subtitle text-xs">
            我的-更换账号-使用身份卡找回）
          </p>
          <p class="subtitle subtitle1 text-xs">
            微勃官网永久网址：www.wbporn1.com
          </p>
        </div>
        <div mt-8>
          <div mt-4 flex justify-center>
            <div class="bg4" round mr-3 h-9 w-31.5 text-center leading-9 ct-ffffff @click="saveCardHandle">
              保存至相册
            </div>
            <div class="bg4" round h-9 w-31.5 text-center leading-9 ct-ffffff @click="showCard = false">
              我已截图保存
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
  <LoadReflesh :show="loading" />
  <TheFooter fixed bottom-0 h-50 w-full />
</div>
</template>

<style scoped>
.bg1 {
  background: linear-gradient(96.52deg, #efbb76 3.96%, #efcf9a 94.87%);
}
.bg2 {
  background: linear-gradient(to right, #939eb4, #d0dbed, #7a88a3);
}
.bg3 {
  background: linear-gradient(90deg, #fe9849 0.84%, #ff3e4e 99.19%, #fe8d21 100%);
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 350px;
  height: 540px;
  background: url('~/assets/image/home/mine/group_seek_film_bg.png') no-repeat;
  background-position: center center;
  background-size: cover;
  color: #e8e8e8;
  border-radius: 10px;
  box-shadow: 0px 2px 3px 0px #9999990a;
  position: relative;
}
.block1 {
  background: url('~/assets/image/home/identity/id_bg.png') no-repeat;
  background-position: center center;
  background-size: cover;
}
.bg-bt {
  background: linear-gradient(130.94deg, #fd9403 16.87%, #fd5b03 89.42%);
  line-height: 44px;
  border-radius: 10px;
}
.bg4 {
  background: linear-gradient(95.57deg, #fd9403 1.92%, #fd4b03 96.87%);
  border-radius: 10px;
  padding:0 6px;
}
:deep(.van-cell) {
  background: transparent;
  color: #e8e8e8;
}
:deep(.van-field__label) {
  color: #e8e8e8;
}
:deep(.van-field__control::placeholder) {
  color: #5c5e69;
}
.gaps{
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background: #FFFFFF4D;
}
.card_bg{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 284px;
  height: 324px;
  background: url('/card_bg.png') no-repeat center/cover;
  .user-name,
  .subtitle{
    color: #000000;
  }
  .subtitle{
    margin-bottom: 6px;
  }
  .subtitle1{
    color: #7C7C7C
  }
}
</style>

<style>
.banner2{
  height: 80px;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
