import type { orderPage, purchasePage, vipInfo } from './model'
import http from '~/utils/http'

// 个人信息
export function personalInfo(params: any) {
  return http.post<any>({
    url: '/jav/personal/info',
    body: params
  })
}

// 充值记录
export function orderRecord(params: orderPage) {
  return http.post<any>({
    url: '/jav/personal/orderRecord',
    body: params
  })
}

// 获取支付类型
export function getJavPayType(params: any) {
  return http.post<any>({
    url: '/jav/personal/getJavPayType',
    body: params
  })
}

// JAV修改会员信息
export function updateInfo(params: vipInfo) {
  return http.post<any>({
    url: '/jav/personal/updateInfo',
    body: params
  })
}

// 购买记录
export function purchaseRecord(params: purchasePage) {
  return http.post<any>({
    url: '/jav/personal/purchaseRecord',
    body: params
  })
}

// 金币余额
export function coinBalance(params: any) {
  return http.post<any>({
    url: '/jav/openvip/coinBalance',
    body: params
  })
}

// 会员卡列表查询
export function vipCardList(params: any) {
  return http.post<any>({
    url: '/jav/openvip/vipCardList',
    body: params
  })
}
