import { cryptoUtils } from '~/utils/crypto/core'
import Cache from '~/utils/cache'
import { MessageEle } from '~/plugins/message.client'
import { getClientType } from '~/utils/helper'

const myHeaders = new Headers()
myHeaders.append('Ob-Application', import.meta.env.VITE_APP_APPLICATION as string)
myHeaders.append('Ob-Client', getClientType() as string)
myHeaders.append('Ob-Encrypted', 'false')
myHeaders.append('Ob-Nonce', cryptoUtils.createNonce().toString())
myHeaders.append('Ob-Secret-Version', import.meta.env.VITE_APP_SECRET_VERSION as string)
myHeaders.append('Ob-Timestamp', cryptoUtils.createTimestamp().toString())
myHeaders.append('X-Request-Token', Cache.token() as string)
myHeaders.append('merchant-id', import.meta.env.VITE_APP_MERCHANT_ID as string)

const baseApi: string = process.client
  ? (import.meta.env.VITE_APP_BASE_API as string)
  : (useRuntimeConfig().serverBaseApi as any)[import.meta.env.VITE_APP_ENV_FLAG]

interface ApiResponse {
  [key: string]: any
  msg?: string
  message?: string
}

export async function httpFetch(file: File): Promise<ApiResponse | void> {
  const formData = new FormData()
  formData.append('file', file)

  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: formData,
    redirect: 'follow'
  }

  try {
    const response = await fetch(`${baseApi}jav/common/uploadJavImg`, requestOptions)
    return await response.json()
  } catch (error: any) {
    const msg = error.msg || error.message || ''
    MessageEle.error(msg || '系统异常，请稍后重试')
  }
}
