import { useDayjs } from '#dayjs'
enum VipRightsLevel {
  Free = 0,
  StarWeekly = 1,
  StarMonthly = 2,
  GoldYearly = 3,
  BlackGoldYearly = 4,
  PlatinumLifetime = 5,
  BlackGold = 6
}

export default class PersonInfo {
  certificateVO?: Certificate
  coinBalance?: number
  freeCoinVideo?: number
  freeNum?: number
  freeNumLimit?: number
  freeVipVideo?: number
  id?: string
  mobile?: string
  nickname?: string
  ticketBalance?: number
  topImagesUrl?: string
  userName?: string
  videoCacheLimit?: number
  videoCacheNum?: number
  videoCollectNum?: number
  vipCoinDiscount?: number
  vipName?: string
  vipRemainDay?: number
  vipRightsLevel?: VipRightsLevel
  merchandiseUrl?: string
  personCenterUrl?: string

  constructor(init?: Partial<PersonInfo>) {
    Object.assign(this, init)
  }

  get balance(): number {
    return this.coinBalance ? Math.round(this.coinBalance) : 0
  }

  get isBindMobile(): boolean {
    return !!this.mobile
  }

  get phoneRemoveArea(): string {
    if (this.mobile) {
      return this.mobile.split('-').pop() || ''
    }
    return ''
  }

  get phoneEncryption(): string {
    const phone = this.phoneRemoveArea
    if (phone) {
      if (phone.length > 4) {
        const phone1 = phone.substring(0, 2)
        const phone2 = phone.substring(phone.length - 2)
        return `${phone1}****${phone2}`
      } else {
        return phone
      }
    }
    return ''
  }

  get isVip(): boolean {
    return (this.vipRemainDay || 0) > 0
  }

  get vipRemainHour(): number {
    return 23 - new Date().getHours()
  }

  get vipColor(): string {
    switch (this.vipRightsLevel) {
      case VipRightsLevel.Free:
        return '#165EC2'
      case VipRightsLevel.StarWeekly:
        return '#DC5C7E'
      case VipRightsLevel.StarMonthly:
        return '#5B29B4'
      case VipRightsLevel.GoldYearly:
        return '#8D6A21'
      case VipRightsLevel.BlackGoldYearly:
        return '#F8D284'
      case VipRightsLevel.PlatinumLifetime:
        return '#C18472'
      case VipRightsLevel.BlackGold:
        return '#F8D284'
      default:
        return '#165EC2'
    }
  }

  get vipDueDateColor(): string {
    switch (this.vipRightsLevel) {
      case VipRightsLevel.Free:
      case VipRightsLevel.StarWeekly:
      case VipRightsLevel.StarMonthly:
      case VipRightsLevel.GoldYearly:
      case VipRightsLevel.PlatinumLifetime:
        return '#333333'
      case VipRightsLevel.BlackGoldYearly:
      case VipRightsLevel.BlackGold:
        return '#FFFFFF'
      default:
        return '#333333'
    }
  }

  get vipDueDate(): string {
    const remainDay = this.vipRemainDay || 0
    if (this.isForeverVip) {
      return '永久' // Replace with your translation for 'Forever'
    } else {
      const dayjs = useDayjs()
      const finalRemainDay = remainDay > 0 ? remainDay - 1 : remainDay
      const date = new Date()
      date.setDate(date.getDate() + finalRemainDay)
      return dayjs(date).format('YYYY/MM/DD')
    }
  }

  get isForeverVip(): boolean {
    const remainDay = this.vipRemainDay || 0
    return remainDay === 36500
  }

  get isFreeVip(): boolean {
    return this.vipRightsLevel === VipRightsLevel.Free
  }

  copyWith(changes: Partial<PersonInfo>): PersonInfo {
    return new PersonInfo({
      ...this,
      ...changes
    })
  }
}
// export default PersonInfo
